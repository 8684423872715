import React from 'react';

const DownloadPlatform = () => {
  const platforms = [
    {
      id: 1,
      name: 'iOS App',
      icon: (
        <svg className="w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
          <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z" />
        </svg>
      ),
      description: 'Download our iOS app for the best mobile experience on your iPhone or iPad.',
      buttonText: 'Download on App Store',
      link: '#',
      features: ['Offline Access', 'Auto Updates']
    },
    {
      id: 2,
      name: 'Android App',
      icon: (
        <svg className="w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
          <path d="M16.61 15.15c-.46 0-.84-.37-.84-.83s.37-.84.84-.84c.46 0 .84.37.84.84s-.37.83-.84.83m-9.2 0c-.46 0-.84-.37-.84-.83s.37-.84.84-.84c.46 0 .84.37.84.84s-.37.83-.84.83m9.5-6.59l1.56-2.7c.09-.15.04-.34-.11-.43-.15-.09-.34-.04-.43.11l-1.58 2.73c-1.3-.53-2.75-.84-4.33-.84-1.58 0-3.03.3-4.33.84L6.11 5.54c-.09-.15-.28-.2-.43-.11-.15.09-.2.28-.11.43l1.56 2.7C3.84 10.25 1.5 13.42 1.5 17h21c0-3.58-2.34-6.75-5.59-8.44z" />
        </svg>
      ),
      description: 'Get our Android app for a seamless experience on your Android device.',
      buttonText: 'Get it on Google Play',
      link: '#',
      features: ['Offline Access', 'Auto Updates']
    },
    {
      id: 3,
      name: 'Web App',
      icon: (
        <svg className="w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
          <path d="M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2 0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8 8M5.08 16H8c.35 1.25.81 2.45 1.41 3.56-1.84-.63-3.37-1.9-4.33-3.56M4.26 14c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56M12 2C6.47 2 2 6.5 2 12s4.47 10 10 10 10-4.5 10-10S17.53 2 12 2z" />
        </svg>
      ),
      description: 'Access our platform directly from your browser, no installation required.',
      buttonText: 'Launch Web App',
      link: '#',
      features: ['Cross-Platform', 'Auto Updates']
    }
  ];

  return (
    <div className="bg-gradient-to-b from-[#3F3A64]/5 to-[#3F3A64]/10 min-h-screen">
      <div className="container mx-auto px-4 py-16">
        
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-[#3F3A64] mb-6">
            Download Our Platform
          </h1>
          <p className="text-xl text-[#3F3A64]/80 max-w-2xl mx-auto">
            Access our platform anywhere, anytime. Choose your preferred platform and get started today.
          </p>
        </div>

        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {platforms.map((platform) => (
            <div
              key={platform.id}
              className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden"
            >

              <div className="p-8 text-center">
                <div className="inline-block p-4 bg-[#20AD96]/10 rounded-full text-[#20AD96] mb-4">
                  {platform.icon}
                </div>
                <h2 className="text-2xl font-bold text-[#3F3A64] mb-4">
                  {platform.name}
                </h2>
                <p className="text-[#3F3A64]/70 mb-6">
                  {platform.description}
                </p>
              </div>

             
              <div className="px-8 pb-6">
                <ul className="space-y-3">
                  {platform.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-[#3F3A64]/70">
                      <svg className="w-5 h-5 text-[#20AD96] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

             
              <div className="p-8 pt-4">
                <a
                  href={platform.link}
                  className="block w-full py-3 px-6 text-center text-white bg-[#FF5D2E] hover:bg-[#FF5D2E]/90 rounded-lg transition-colors duration-300"
                >
                  {platform.buttonText}
                </a>
              </div>
            </div>
          ))}
        </div>

       
        <div className="text-center mt-16">
          <p className="text-[#3F3A64]/70">
            Need help? <a href="#" className="text-[#20AD96] hover:text-[#20AD96]/80 hover:underline">Contact our support team</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DownloadPlatform;