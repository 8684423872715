import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = ({ targetRef }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const testimonials = [
    {
      id: 1,
      name: "Sarah Johnson",
      role: "Science Student",
      university: "Stanford University",
      image: "https://smart-learners.vercel.app/static/media/report2.147e141fe64428479b11.png",
      quote: "This platform completely transformed my learning experience.",
      rating: 5,
      course: "Full Stack Development",
      background: "bg-orange-50",
    },
    {
      id: 2,
      name: "Michael Chen",
      role: "Visual Arts Student",
      university: "MIT",
      image: "https://smart-learners.vercel.app/static/media/report2.147e141fe64428479b11.png",
      quote: "The quality of instruction and the community support are unmatched. I've learned more in 6 months here than in 2 years of traditional education.",
      rating: 5,
      course: "Visual Arts",
      background: "bg-green-50",
    },
    {
      id: 3,
      name: "Emma Thompson",
      role: "General Arts Student",
      university: "Royal College of Art",
      image: "https://smart-learners.vercel.app/static/media/report2.147e141fe64428479b11.png",
      quote: "The practical approach to design education here is incredible. ",
      rating: 5,
      course: "General Arts",
      background: "bg-slate-50",
    },
    
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: (current, next) => setActiveSlide(next),
    customPaging: (i) => (
      <div className={`w-3 h-3 rounded-full transition-all duration-300 ${i === activeSlide ? 'bg-[#FF5C2F] w-6' : 'bg-gray-300'}`} />
    ),
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <svg
        key={index}
        className={`w-5 h-5 ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    ));
  };

  return (
    <div id="testimonials" ref={targetRef} className="bg-gradient-to-b from-white to-gray-50 py-20 overflow-hidden">
      <div className="container mx-auto px-4">
        
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-[#3F3A64] mb-6">
            What Our Students Say
          </h2>
          <p className="text-xl text-gray-600">
            Discover how our platform has helped students achieve their learning goals
            and advance their careers.
          </p>
        </div>

       
        <div className="max-w-6xl mx-auto">
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id}>
                <div className={`${testimonial.background} rounded-2xl p-8 md:p-12 mx-2`}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                    
                    <div className="text-center md:text-left">
                      <div className="mb-6 relative inline-block">
                        <div className="w-24 h-24 md:w-32 md:h-32 rounded-full overflow-hidden ring-4 ring-white shadow-lg">
                          <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="absolute -bottom-3 -right-3 bg-[#FF5C2F] text-white p-2 rounded-full">
                          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                      </div>
                      <h3 className="text-2xl font-bold text-gray-800 mb-2">{testimonial.name}</h3>
                      <p className="text-[#20AD96] font-medium mb-1">{testimonial.role}</p>
                      <p className="text-gray-600 mb-4">{testimonial.university}</p>
                      <div className="flex items-center justify-center md:justify-start space-x-1 mb-4">
                        {renderStars(testimonial.rating)}
                      </div>
                      <div className="inline-block bg-white px-4 py-2 rounded-full text-sm font-medium text-[#FF5C2F]">
                        {testimonial.course}
                      </div>
                    </div>

                   
                    <div className="relative">
                      <svg className="absolute top-0 left-0 w-16 h-16 text-blue-200 transform -translate-x-6 -translate-y-6 opacity-50" fill="currentColor" viewBox="0 0 32 32">
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <blockquote className="relative z-10">
                        <p className="text-xl md:text-2xl text-gray-700 leading-relaxed">
                          "{testimonial.quote}"
                        </p>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

      
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto mt-20">
          <div className="text-center p-6">
            <div className="text-4xl font-bold text-[#FF5C2F] mb-2">98%</div>
            <div className="text-gray-600">Student Satisfaction</div>
          </div>
          <div className="text-center p-6">
            <div className="text-4xl font-bold text-[#20AD96] mb-2">50k+</div>
            <div className="text-gray-600">Success Stories</div>
          </div>
          <div className="text-center p-6">
            <div className="text-4xl font-bold text-[#FF5C2F] mb-2">4.9/5</div>
            <div className="text-gray-600">Average Rating</div>
          </div>
        </div>
      </div>

     
      <style jsx>{`
        .slick-dots {
          bottom: -3rem;
        }
        .slick-dots li {
          width: 3rem;
          margin: 0;
        }
      `}</style>
    </div>
  );
};

export default Testimonials;