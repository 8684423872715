import React from 'react';

const AccestoCourse = () => {
  return (
    <div className="relative min-h-[500px] flex items-center">
     
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-[#3F3A64]/90 to-[#20AD96]/90 backdrop-blur-sm"></div>
      </div>

      
      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
        
          <div className="inline-block animate-bounce mb-8">
            <div className="bg-gradient-to-r from-[#FF5C2F] to-[#FF5C2F]/80 text-white px-6 py-2 rounded-full font-bold text-lg shadow-lg">
              Starting at GH₵10 only!
            </div>
          </div>

        
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight">
            Unlock Your Learning Potential
            <span className="block text-[#FF5C2F]">Without Breaking the Bank</span>
          </h1>

          
          <p className="text-xl md:text-2xl text-gray-200 mb-8 leading-relaxed">
            Get unlimited access to premium educational content across all subjects.
            Learn at your own pace, anytime, anywhere.
          </p>

         
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
            <div className="bg-[#3F3A64]/10 backdrop-blur-md rounded-xl p-6 transform hover:scale-105 transition-transform duration-300">
              <div className="text-[#20AD96] mb-4">
                <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
              </div>
              <h3 className="text-white text-xl font-bold mb-2">Full Access</h3>
              <p className="text-gray-300">All subjects and topics available instantly</p>
            </div>

            <div className="bg-[#3F3A64]/10 backdrop-blur-md rounded-xl p-6 transform hover:scale-105 transition-transform duration-300">
              <div className="text-[#20AD96] mb-4">
                <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
              </div>
              <h3 className="text-white text-xl font-bold mb-2">Video Lessons</h3>
              <p className="text-gray-300">HD quality educational videos</p>
            </div>

            <div className="bg-[#3F3A64]/10 backdrop-blur-md rounded-xl p-6 transform hover:scale-105 transition-transform duration-300">
              <div className="text-[#20AD96] mb-4">
                <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-white text-xl font-bold mb-2">Verified Content</h3>
              <p className="text-gray-300">Expert-reviewed materials</p>
            </div>
          </div>

        
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button onClick={() => {
              window.open("https://app.smartlearnersonline.com/");
            }} className="bg-[#FF5C2F] hover:bg-[#FF5C2F]/90 text-white px-8 py-4 rounded-full font-bold text-lg transform hover:scale-105 transition-all duration-300 shadow-lg">
              Start Learning Now
            </button>
            <button className="bg-[#20AD96] hover:bg-[#20AD96]/90 text-white px-8 py-4 rounded-full font-bold text-lg transform hover:scale-105 transition-all duration-300 shadow-lg">
              View All Courses
            </button>
          </div>

         
          <div className="mt-12 flex flex-wrap justify-center gap-8 items-center">
            <div className="text-white">
              <span className="block text-3xl font-bold">50K+</span>
              <span className="text-gray-300">Active Students</span>
            </div>
            <div className="text-white">
              <span className="block text-3xl font-bold">1000+</span>
              <span className="text-gray-300">Video Lessons</span>
            </div>
            <div className="text-white">
              <span className="block text-3xl font-bold">4.9/5</span>
              <span className="text-gray-300">Student Rating</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccestoCourse;