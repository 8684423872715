import React from 'react';

const ClassCards = ({ image, title, description, bgColor }) => {
    return (
        <div className="relative group overflow-hidden rounded-2xl bg-white shadow-lg">
            {/* Image Container with Overlay */}
            <div className="relative h-48 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10"/>
                <img 
                    src={image} 
                    alt={title} 
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                {/* Decorative Element */}
                <div 
                    className="absolute -right-4 -bottom-4 w-24 h-24 rounded-full opacity-20"
                    style={{ backgroundColor: bgColor }}
                />
            </div>

            {/* Content Container */}
            <div className="relative p-6 z-20">
                {/* Icon Container */}
                <div 
                    className="w-12 h-12 rounded-xl flex items-center justify-center mb-4 transform -translate-y-12"
                    style={{ backgroundColor: bgColor }}
                >
                    <svg 
                        className="w-6 h-6 text-white"
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                    </svg>
                </div>

                {/* Text Content */}
                <div className="-mt-8">
                    <h3 
                        className="text-xl font-bold mb-2 text-[#3F3A64] group-hover:text-[#FF5F2F] transition-colors duration-300"
                    >
                        {title}
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                        {description}
                    </p>
                </div>

                {/* Read More Link */}
                <div className="mt-4 flex items-center text-sm font-semibold" style={{ color: bgColor }}>
                    <span className="mr-2">Learn More</span>
                    <svg 
                        className="w-4 h-4 transform group-hover:translate-x-2 transition-transform duration-300" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default ClassCards;