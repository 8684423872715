import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TrustSchools = () => {
   
    const trustedSchools = [
        {
            id: 1,
            name: "Regent Academy",
            image: "https://placeholder.co/200x100",
            // location: "Cambridge, MA",
            // yearJoined: "2021",
        },
        {
            id: 2,
            name: "Anioted Universal College",
            image: "https://placeholder.co/200x100",
            // location: "Stanford, CA",
            // yearJoined: "2021",
        },
        // {
        //     id: 3,
        //     name: "MIT",
        //     image: "https://placeholder.co/200x100",
        //     location: "Cambridge, MA",
        //     yearJoined: "2022",
        // },
        // {
        //     id: 4,
        //     name: "Oxford University",
        //     image: "https://placeholder.co/200x100",
        //     location: "Oxford, UK",
        //     yearJoined: "2022",
        // },
        // {
        //     id: 5,
        //     name: "Cambridge University",
        //     image: "https://placeholder.co/200x100",
        //     location: "Cambridge, UK",
        //     yearJoined: "2022",
        // },
        // Add more schools as needed
    ];

    
    const settings = {
        dots: true,
        infinite: trustedSchools.length > 4,
        speed: 500,
        slidesToShow: Math.min(4, trustedSchools.length),
        slidesToScroll: 1,
        autoplay: trustedSchools.length > 4,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(2, trustedSchools.length),
                    slidesToScroll: 1,
                    infinite: trustedSchools.length > 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: trustedSchools.length > 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: trustedSchools.length > 1
                }
            }
        ]
    };

    return (
        <div className="bg-[#F9F9F9] py-16">
            <div className="container max-w-[90rem] mx-auto px-4">
                
                <div className="text-center mb-12">
                    <h2 className="lg:text-5xl text-3xl font-bold text-[#3F3A64] mb-4">
                        Trusted by Leading Schools
                    </h2>
                    <p className="text-[#3F3A64]/70 max-w-2xl mx-auto lg:text-xl text-base">
                        Join the growing community of educational institutions that trust our platform
                        for their digital learning needs.
                    </p>
                </div>

                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <div className="text-center p-6 bg-white rounded-lg shadow-sm">
                        <div className="text-3xl font-bold text-[#20AD96] mb-2">2</div>
                        <div className="text-[#3F3A64]">Partner Schools</div>
                    </div>
                    <div className="text-center p-6 bg-white rounded-lg shadow-sm">
                        <div className="text-3xl font-bold text-[#20AD96] mb-2">4+</div>
                        <div className="text-[#3F3A64]">Countries</div>
                    </div>
                    <div className="text-center p-6 bg-white rounded-lg shadow-sm">
                        <div className="text-3xl font-bold text-[#20AD96] mb-2">500+</div>
                        <div className="text-[#3F3A64]">Students Reached</div>
                    </div>
                </div>

              
                <div className="relative mx-auto w-full">
                    <Slider {...settings} className="trusted-schools-slider">
                        {trustedSchools.map((school) => (
                            <div key={school.id} className="px-1">
                                <div className="bg-white p-4 mx-1 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                    <div className="aspect-w-16 aspect-h-9 mb-4">
                                        <img
                                            src={school.image}
                                            alt={school.name}
                                            className="object-contain w-full h-24 mx-auto"
                                        />
                                    </div>
                                    <div className="text-center">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">
                                            {school.name}
                                        </h3>
                                        <p className="text-sm text-gray-600 mb-1">{school.location}</p>
                                        <p className="text-sm text-gray-500">Partner since {school.yearJoined}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                
                <div className="text-center mt-16">
                    <p className="text-[#3F3A64]/70 mb-6">
                        Want to become a partner institution?
                    </p>
                    <button className="bg-[#FF5C2F] text-white px-8 py-3 rounded-lg hover:bg-[#FF5C2F]/90 transition-colors duration-300">
                        Join Our Network
                    </button>
                </div>
            </div>

           
            <style jsx>{`
        .trusted-schools-slider .slick-dots li button:before {
          color: #20AD96;
        }
        .trusted-schools-slider .slick-dots li.slick-active button:before {
          color: #FF5C2F;
        }
        .trusted-schools-slider .slick-prev:before,
        .trusted-schools-slider .slick-next:before {
          color: #20AD96;
        }
      `}</style>
        </div>
    );
};

export default TrustSchools;