import React, { useState, useEffect } from 'react';
import smllogo from "../assets/smllogo.png";

const Header = ({ targetRef }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

   
    const navItems = [
        { name: 'Home', href: '#home' },
        { name: 'Courses', href: '#courses' },
        // { name: 'About Us', href: '#about' },
        { name: 'Testimonials', href: '#testimonials' },
        { name: 'Contact', href: '#contact' },
    ];

   
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTarget = () => {
        targetRef.current?.scrollIntoView({ behavior: 'smooth' });
        setIsMobileMenuOpen(false);
    };

    const handleScroll = (e, href) => {
        e.preventDefault();
        const element = document.querySelector(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
           
            <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
                }`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2">
                    <div className="flex justify-between items-center h-24 py-4">
                       
                        <div className="flex-shrink-0">
                            <img
                                className=" h-20 w-auto"
                                src={smllogo}
                                alt="Logo"
                            />
                        </div>

                       
                        <nav className="hidden md:flex items-center space-x-8">
                            {navItems.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    onClick={(e) => handleScroll(e, item.href)}
                                    className="text-[#3F3A64] hover:text-[#20AD96] px-3 py-2 text-base font-medium transition-colors duration-300"
                                >
                                    {item.name}
                                </a>
                            ))}
                        </nav>

                      
                        <div className="hidden md:flex items-center space-x-6">
                       
                            <div className="flex items-center space-x-2">
                                <div className="bg-[#20AD96]/10 p-2 rounded-full">
                                    <svg
                                        className="w-6 h-6 text-[#20AD96]"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                        />
                                    </svg>
                                </div>
                                <span className="text-[#3F3A64] text-lg font-medium">+233 0203001230</span>
                            </div>

                          
                            <button
                                onClick={() => {
                                    window.open("https://app.smartlearnersonline.com/");
                                }}
                                className="inline-flex items-center px-6 py-2.5 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-[#20AD96] to-[#3F3A64] hover:from-[#1c9884] hover:to-[#332f52] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#20AD96] transform hover:scale-105 transition-all duration-300"
                            >
                                Get Started
                                <svg
                                    className="ml-2 -mr-1 w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                                    />
                                </svg>
                            </button>
                        </div>

                      
                        <div className="md:hidden">
                            <button
                                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-[#3F3A64] hover:text-[#20AD96] hover:bg-[#20AD96]/10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#20AD96]"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isMobileMenuOpen ? (
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                ) : (
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

              
                <div className={`md:hidden transition-all duration-300 ease-in-out ${isMobileMenuOpen ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0'
                    } overflow-hidden`}>
                    <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
                        {navItems.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                // onClick={(e) => handleScroll(e, item.href)}
                                className="block px-3 py-2 rounded-md text-lg font-medium text-[#3F3A64] hover:text-[#20AD96] hover:bg-[#20AD96]/10"
                            // onClick={() => setIsMobileMenuOpen(false)}
                            >
                                {item.name}
                            </a>
                        ))}
                        <div className="px-3 py-2">
                            <button
                                // onClick={scrollToTarget}
                                onClick={() => {
                                    window.open("https://app.smartlearnersonline.com/");
                                }}
                                className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-[#20AD96] to-[#3F3A64] hover:from-[#1c9884] hover:to-[#332f52]"
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </header>

           
            <div className="h-24"></div>

            
            <style jsx global>{`
                html {
                    scroll-behavior: smooth;
                }
            `}</style>
        </>
    );
};

export { Header };