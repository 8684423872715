import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Maths from '../assets/Maths.webp';
import English from '../assets/English.jpg';
import Science from '../assets/Science.jpg';
import SocialStudies from '../assets/Social.jpg';
import Accounting from '../assets/Accounting.avif';
import Physics from '../assets/Physics.webp';
import Chemistry from '../assets/Chemistry.webp';
import Biology from '../assets/biology.jpg';
import VisualArts from '../assets/Visual.jpg';

const ExplorerCourses = ({ targetRef }) => {
   

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '40px',
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '30px',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '20px',
                }
            }
        ],
        centerMode: true,
        centerPadding: '60px',
    };


    const courses = [
        {
            id: 1,
            title: "Mathematics",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: Maths,
        },
        {
            id: 1,
            title: "English",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: English,
        },
        {
            id: 1,
            title: "Science",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: Science,
        },
        {
            id: 1,
            title: "Social Studies",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: SocialStudies,
        },
        {
            id: 1,
            title: "Accounting",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: Accounting,
        },
        {
            id: 1,
            title: "Physics",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: Physics,
        },
        {
            id: 1,
            title: "Chemistry",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: Chemistry,
        },
        {
            id: 1,
            title: "Bilogy",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: Biology,
        },
        {
            id: 1,
            title: "Visual Arts",
            instructor: "John Doe",
            level: "Beginner",
            duration: "8 weeks",
            rating: 4.8,
            students: 1234,
            image: VisualArts,
        },

    ];

    return (
        <div id="courses" ref={targetRef} className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-20 overflow-hidden">
            <div className="mb-12 text-center">
                <h1 className="text-3xl md:text-5xl font-bold mb-4" style={{ color: '#3F3A64' }}>
                    Explore Our Courses
                </h1>
                <p className="text-gray-600 max-w-2xl mx-auto text-base lg:text-xl">
                    Discover a wide range of courses designed to help you achieve your learning goals
                </p>
            </div>


            <div className="course-carousel">
                <Slider {...settings}>
                    {courses.map((course) => (
                        <div
                            key={course.id}
                            className="group bg-white rounded-xl overflow-hidden cursor-pointer transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl px-2 "
                        >

                            <div className="aspect-[4/3] relative">
                                <img
                                    src={course.image}
                                    alt={course.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>


                            <div className="p-4">
                                <h3 className="text-lg font-semibold text-gray-800 text-center">
                                    {course.title}
                                </h3>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

        </div>
    )
}

export default ExplorerCourses