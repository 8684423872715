import React, { useRef } from 'react';
import { Header } from '../Components/Header';
import Forms from '../Components/Forms';
import Hero2 from '../Components/Hero2';
import ExplorerCourses from '../Components/ExplorerCourses';
import DownloadPlatform from '../Components/DownloadPlatform';
import TrustSchools from '../Components/TrustSchools';
import Testimonials from '../Components/Testimonials';
import AccestoCourse from '../Components/AccestoCourse';
import ContactSections from '../Components/ContactSections';
import { Footer2 } from '../Components/Footer2';




const HomePage = () => {
  const targetRef = useRef(null);
  return (
    <>
      <Header targetRef={targetRef} />
      <Hero2 targetRef={targetRef} />
      <ExplorerCourses targetRef={targetRef} />
      <DownloadPlatform />
      <TrustSchools />
      <div className='bg-[#F2F2FB] px-4 lg:px-44'>
        <Forms />
      </div>
      <Testimonials targetRef={targetRef} />
      <AccestoCourse />
      <ContactSections targetRef={targetRef} />
      <Footer2 />
    </>
  )
}

export default HomePage;