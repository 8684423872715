import React from 'react';
import report from "../assets/report2.png";

const Hero2 = ({ targetRef }) => {
    return (
        <div id="home" ref={targetRef} className="bg-gradient-to-r from-[#F2F2FB] to-[#F7FFFE] min-h-[90vh]">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-20 py-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                <div className="absolute inset-0 overflow-hidden hidden md:block">
                    <div className="absolute top-1/4 left-28 w-40 h-40 rounded-full bg-[#3F3A64] opacity-10"></div>
                    {/* <div className="absolute right-8 top-1/3 w-32 h-32 rounded-full bg-[#1C9884] opacity-10"></div> */}
                    <div className="absolute left-1/3 bottom-1/4 w-40 h-40 rounded-full bg-[#20AD96] opacity-10"></div>
                </div>
                   
                    <div className="space-y-8 relative">
                        <div className="absolute -top-36 left-1/3 w-40 h-40 rounded-full bg-[#FF5F2F] opacity-10 hidden md:block"></div>
                        <h1 className="text-3xl md:text-5xl font-bold text-[#3F3A64]">
                            Your Path to Academic Excellence Starts Here
                        </h1>
                        <p className="text-lg text-gray-600">
                            Join thousands of high school students who are transforming their learning experience.
                            Get access to expert-led courses, interactive study materials, and a supportive learning community.
                        </p>
                        <div className="space-x-4">
                            <button onClick={() => {
                                window.open("https://app.smartlearnersonline.com/");
                            }} className="bg-[#20AD96] text-white px-8 py-3 rounded-lg font-semibold hover:bg-[#1c9884] transition duration-300">
                                Get Started Free
                            </button>
                            <button className="border border-[#3F3A64] text-[#3F3A64] px-8 py-3 rounded-lg font-semibold hover:bg-[#3F3A64] hover:text-white transition duration-300 hidden md:inline-block">
                                Explore Courses
                            </button>
                        </div>
                        <div className="flex items-center space-x-8 text-sm text-[#3F3A64]">
                            <div className="flex items-center">
                                <svg className="w-5 h-5 text-[#FF612F] mr-2" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                                <span>5+ Courses</span>
                            </div>
                            <div>• 500+ Active Students</div>
                            <div>• 10+ Subjects</div>
                        </div>
                    </div>

                  
                    <div className="relative">
                        <div className="relative z-10">
                            <img
                                src={report}
                                alt="Student learning online"
                                className="w-full h-auto "
                            />
                        </div>
                        
                        <div className="absolute -z-10 top-0 right-0 w-72 h-72 bg-[#20AD96] rounded-full filter blur-3xl opacity-20"></div>
                        <div className="absolute -z-10 bottom-0 left-0 w-72 h-72 bg-[#FF612F] rounded-full filter blur-3xl opacity-20"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero2