import React from 'react'
import ClassCards from './ClassCards'
import image1 from "../../src/assets/image1.jpg";
import image2 from "../../src/assets/image3.jpg";
import image3 from "../assets/image2.jpg";

const Forms = () => {
    const Data = [
        {
            image: image1,
            title: "Learn Anywhere and Anytime",
            description: "Access quality education anytime, anywhere, with our mobile-friendly learning solutions.",
            bgColor: "#FF5F2F"
        },
        {
            image: image2,
            title: "1000+ Learning Resources",
            description: "Explore a vast library of educational materials to fuel your growth.",
            bgColor: "#1C9884"
        },
        {
            image: image3,
            title: "Mentoring by certified Teachers",
            description: "Achieve academic excellence with expert support and mentorship.",
            bgColor: "#3F3A64"
        }
    ]
    return (
        <div className="relative  to-gray-50">
          
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute -left-4 top-1/4 w-24 h-24 rounded-full bg-[#FF5F2F] opacity-10"></div>
                <div className="absolute right-8 top-1/3 w-32 h-32 rounded-full bg-[#1C9884] opacity-10"></div>
                <div className="absolute left-1/3 bottom-1/4 w-40 h-40 rounded-full bg-[#3F3A64] opacity-10"></div>
            </div>

            <div className="container mx-auto px-4 py-16 relative">
              
                <div className='py-12 md:py-20 grid place-items-center'>
                    <div className='w-full md:w-4/5 lg:w-3/5 space-y-8'>
                        <div className="text-center space-y-4">
                            <span className="inline-block px-4 py-2 bg-[#FF5F2F] bg-opacity-10 rounded-full text-[#FF5F2F] text-sm font-semibold mb-4">
                                Educational Excellence
                            </span>
                            <h1 className='text-center text-4xl md:text-5xl lg:text-6xl text-[#3F3A64] font-bold leading-tight'>
                                No one is left behind!
                            </h1>
                            <p className='text-base md:text-xl text-center text-[#767390] max-w-2xl mx-auto leading-relaxed'>
                                Our lessons are designed to match the needs of students across various grades, including Primary, Junior Secondary, and Senior Secondary levels.
                            </p>
                        </div>
                    </div>
                </div>

               
                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10 mt-12'>
                    {Data.map((list, index) => (
                        <div key={index} 
                             className="transform transition-all hover:scale-105 hover:-translate-y-2 duration-300 hover:shadow-xl"
                        >
                            <ClassCards 
                                image={list.image} 
                                description={list.description} 
                                title={list.title}
                                bgColor={list.bgColor}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Forms